import React from "react"
import { navigate } from "gatsby"
import "./form.css"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class Contact extends React.Component {
  state = {
    contactName: "",
    contactEmail: "",
    contactInput: "",
    formError: false,
  }

  handleInputChange = e => {
    if (e.target.name === "guestName") {
      this.guestlistListener(e)
    }
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = event => {
    const contactName = this.state.contactName
    const contactEmail = this.state.contactEmail
    const contactInput = this.state.contactInput
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        contactName,
        contactEmail,
        contactInput,
      }),
    })
      .then(() => navigate("/gotcha"))
      .catch(error => alert(error))
    event.preventDefault()
  }

  render() {
    return (
      <div className="main">
        <div className="border-bottom-wrapper">
          <div className="border-bottom">
            <div className="full-width">
              <div className="contact-form">
                <h2>Text messages preferred!</h2>
                <h4>
                  ...but if you don't have a number for either of us, <br />
                  drop us a note and we'll remedy the situation.
                </h4>
                <br />
                <br />
                <form
                  name="contact"
                  method="post"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  // autoComplete="off"
                  action="/gotcha"
                  onSubmit={this.handleSubmit}
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />
                  <input
                    type="text"
                    name="contactName"
                    id="contactName"
                    // autoComplete="off"
                    placeholder="your name"
                    value={this.state.contactName}
                    onChange={this.handleInputChange}
                    style={{ marginBottom: "20px" }}
                  />
                  <input
                    type="email"
                    name="contactEmail"
                    id="contactEmail"
                    placeholder="you@cyberspace.web"
                    value={this.state.contactEmail}
                    onChange={this.handleInputChange}
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  />
                  <input
                    type="text"
                    name="contactInput"
                    id="contactInput"
                    // autoComplete="off"
                    placeholder="your message"
                    value={this.state.contactInput}
                    onChange={this.handleInputChange}
                    style={{ marginTop: "20px" }}
                  />
                  <input
                    style={{ marginTop: "20px" }}
                    disabled={this.state.formError ? true : undefined}
                    type="submit"
                    className="button-link"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
